import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../reducers';

const initialState = {};

const middleware = [thunk];

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ latency: 0 })) ||
  compose;

const persistConfig = {
  key: 'root',
  storage,
  //whitelist: [''], //do not persist anything
  whitelist: ['cache'],
  // blacklist: ["code_config"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


/**
 * @function
 * @name configureStore
 * @description It creates redux store with persistedReducer,
 * which lets us read the state, dispatch actions and subscribe to changes.
 *
 * @returns {Object}
 *
 * @example
 * console.log(configureStore()); //{ store, persistor }
 */
const configureStore = () => {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('../reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
