/**
 * @memberof module:util
 * @function
 * @name isDevENV
 * @description Check whether Node env. is in development.

 * @returns {boolean}
 *
 * @example
 * console.log(isDevENV()); //true, if it's in development node env.
 * console.log(isDevENV()); //false, if it's in production node env.
 */
export const isDevENV = () => process.env.NODE_ENV === 'development'; // make it ===
