import { SET_DASHBOARD_INIT_JSON } from '../actions/types';


/**
 * @module dashboard_reducer
 * @redux
 * @description It the redux reducer for dashboard.
 * @category Dashboard Page
 * @subcategory reducer
 */


/**
 * @type {State}
 * @property {Object} shipmentAndBooking - Initial json for dashboard
 */
const INITIAL_STATE = {
  shipmentAndBooking: {}, //init json for dashboard
};

/**
 * @memberof module:dashboard_reducer
 * @description It's the dashboard reducer.
 * @type {Redux.Reducer}
 *
 * @param {Redux.Store} state - The current redux state of the dashboard reducer
 * @param {Redux.Action} action - Redux cache action
 *
 * @returns {Redux.Store} The updated redux state
 *
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DASHBOARD_INIT_JSON:
      return {
        ...state,
        shipmentAndBooking: action.payload,
      };

    default:
      return state;
  }
};
