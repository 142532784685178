import {
  SET_CODE_SETTINGS,
  SET_SHOW_RESULT_CARD,
  UPDATE_FCL_COUNTER,
  UPDATE_LCL_SHIPMENT,
  UPDATE_AIR_SHIPMENT,
  RESET_CONTAINER_TYPE_SLIDER,
  SET_INIT_AUTH,
  // SET_AIR_SLIDER_LOADING,
  SET_AIR_JSON,
  SET_RECAPTCHA_RES_CODE,
  SET_DATA_POLLING,
  SET_SEARCH_CUSTOM_MESSAGE_JSON,
  SET_FORM_ZIPCODE_LOADING,
  SET_OVERLAY_FULL_SCREEN,
  SET_TRANSPARENT_OVERLAY_SCREEN,
  SET_REFERRAL_DATA,
  SET_UUID_URL_PARAMS_DATA,
  UPDATE_SIDEBAR_ROLE_INCOTERM_SERVICES,
  UPDATE_PARENT_SCROLLBAR_POSITION,
  UPDATE_RATE_CARD_INFO,
  SHOW_SEARCH_BLUR_CARDS,
  UPDATE_TRANSACTION_TYPE
} from '../actions/types';
import { isDevENV } from '../util/isDevENV';

/**
 * @module code_config_reducer
 * @redux
 * @description It the redux reducer related to web app configuration.
 */

/**
 * @type {State}
 * @property {number} [searchbarHeight=116] - height of top [search bar]{@link Searchbar} section in px
 * @property {boolean} useCustomDropdown - whether to use custom ports dropdown not google map 
 * in origin and destination input fields in [search bar]{@link Searchbar}
 * @property {boolean} showNoResultCard - whether to show [No Result]{@link NoResult} card
 * 
 * @property {Object} fclCounters - [FCL container]{@link FCLContainerType} counts on search page
 * @property {number} fclCounters._20stCounter - FCL 20 Standard container count
 * @property {number} [fclCounters._40stCounter=1] - FCL 40 Standard container count
 * @property {number} fclCounters._40hqCounter - FCL 40 High Cube container count
 * @property {number} fclCounters._45hqCounter - FCL 45 High Cube container count
 * 
 * @property {Object} lclShipment - [LCL shipment]{@link LclAirSlider} data on search page
 * @property {number} [lclShipment.totalWeight=100] - total weight
 * @property {string} [lclShipment.weightUnit=KG] - weight unit
 * @property {number} [lclShipment.totalVolume=1] - total volume
 * @property {string} [lclShipment.volumeUnit=CBM] - volume unit
 * 
 * @property {Object} airShipment - [AIR shipment]{@link LclAirSlider} data on search page
 * @property {number} [airShipment.totalWeight=1] - total weight
 * @property {string} [airShipment.weightUnit=KG] - weight unit
 * @property {number} [airShipment.totalVolume=1] - total volume
 * @property {string} [airShipment.volumeUnit=CBM] - volume unit
 * 
 * @property {string} [primaryColor=rgb(30, 148, 241)] - primary color of web app
 * @property {string} [secondaryColor=rgb(250, 90, 75)] - secondary color of web app
 * 
 * @property {boolean} showOpenMap - whether to show map beside view-details section
 * 
 * @property {Object} initAuth - initial authentication info
 * @property {string} initAuth.user - user param in url
 * @property {string} initAuth.verification - verification param in url
 * 
 * @property {Object} referralData - referral data
 * @property {string} referralData.referral_code - referral_code param in url
 * @property {string} referralData.referral_type - referral_type param in url
 * 
 * @property {Object} uuidUrlParamsObj - referral data
 * @property {string} uuidUrlParamsObj.req_uuid - req_uuid param in url
 * @property {string} uuidUrlParamsObj.uuid_verification - uuid_verification param in url
 * 
 * @property {string} searchContactFormHeader - Header text for Contact form above all result cards on search page.
 * @property {string} searchContactFormSubHeader - Sub Header text description for Contact form above all result cards on search page.
 * @property {boolean} showReCaptcha - Whether to make google invisible recaptcha visible
 * @property {string} reCaptchaResCode - google reCaptcha response code
 * 
 * @property {boolean} dataPolling - whether to disable capability of data polling (api request) by uuid on landing page
 * @property {Object} searchCustomMsgJSON - [custom message]{@link CustomMessage} is shown on top of results cards on search page
 * 
 * @property {string} landingPgInputTextColor - Input text field color on Landing page.
 * @property {boolean} removeFcl45HcContainer - Whether to remove 45' HC from fcl container
 * @property {boolean} [showPortSwitcher=false] - Whether to show port switcher button between origin shipment and destination shipment input field on search page.
 * 
 * @property {string} [searchCardEstimateText=''] - Estimate Tooltip text on a estimate type result card on search page.
 * @property {boolean} showFormCustomService - Whether to show Custom service on form page.
 * @property {string} viewDetailsReviewText - Reviews text to be shown on view-details before booking if user has not viewed it before. 
 * Do not show it if card is of variable rate type
 * 
 * @property {Object} formZipCodeSubmit - whether pickup/delivery should show loading on rate card on search page, 
 * upon submit of Form zipcode submit for new/existing factory.
 * @property {boolean} formZipCodeSubmit.isPickupLoading - whether pickup show a loading spinner
 * @property {boolean} formZipCodeSubmit.isDeliveryLoading - whether delivery show a loading spinner
 * 
 * @property {boolean} [showOverlayFullScreen=false] - whether to show full screen Overlay
 * 
 * @property {number} searchDaysPast - DatePicker past dates limit on search page
 * @property {number} searchDaysFuture - DatePicker future dates limit on search page
 * @property {number} formDaysPast - DatePicker past dates limit on form page.
 * @property {number} formDaysFuture - DatePicker future dates limit on form page.
 * 
 * @property {string} cardVariableRateText - Popover text for variable rate type card on search page
 * 
 * @property {boolean} showFormDeliveryEcommerce - Whether to show e-commerce option in delivery destination location type on form page.
 * @property {string} formDeliveryEcommerceDescText - Delivery destination location type, e-commerce description text on form page.
 * @property {string} formDeliveryAmazonDescText - Delivery destination location type, amazon warehouse description text on form page.
 * @property {boolean} showFormDeliveryAmazon - Whether to show amazon option in delivery destination location type on form page.
 * 
 * @property {string} searchCardRatingStarColor - Star rating color on company name section on a result card.
 * 
 * @property {Object} sidebarRoleIncotermServices - states of sidebar service roles checkboxes on search page.
 * @property {null | boolean} [pickup=null] - whether pickup checkbox is checked
 * @property {null | boolean} [origin=null] - whether origin checkbox state is checked
 * @property {null | boolean} [freight=null] - whether freight checkbox state is checked
 * @property {null | boolean} [discharge=null] - whether discharge checkbox state is checked
 * @property {null | boolean} [ers=null] - whether ers checkbox state is checked
 * @property {null | boolean} [delivery=null] - whether delivery checkbox state is checked
 * 
 * @property {number} parentScrollbarPosition - parent website scrollbar position
 * @property {Object} rateCardsExpandInfo - it contains rate cards state whether cards are expanded or not.
 * 
 * @property {boolean} enableSearchBlurCards - whether to show blur cards or not on search page.
 * 
 */
const INITIAL_STATE = {
  searchbarHeight: 116,
  // scrollTop: 0, //for vertical scroll position
  useCustomDropdown: false,
  showNoResultCard: false,
  fclCounters: {
    _20stCounter: isDevENV() ? 0 : 0,
    _40stCounter: isDevENV() ? 1 : 1, // make it 1 in production
    _40hqCounter: isDevENV() ? 0 : 0,
    _45hqCounter: isDevENV() ? 0 : 0,
  },
  lclShipment: {
    totalWeight: 100,
    weightUnit: 'KG',
    totalVolume: 1,
    volumeUnit: 'CBM',
  },
  airShipment: {
    totalWeight: 1,
    weightUnit: 'KG',
    totalVolume: 1,
    volumeUnit: 'CBM',
  },
  transactionType: 'Importer',
  // isAirSliderLoading: false, //make it false
  primaryColor: process.env.REACT_APP_PRIMARY_COLOR || 'rgb(30, 148, 241)',
  secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || 'rgb(250, 90, 75)',
  showOpenMap: process.env.REACT_APP_SHOW_OPEN_MAP.toLowerCase() === 'yes',
  initAuth: {},
  referralData: {},
  uuidUrlParamsObj: {},
  searchContactFormHeader: process.env.REACT_APP_SEARCH_CONTACT_FORM_HEADER,
  searchContactFormSubHeader:
    process.env.REACT_APP_SEARCH_CONTACT_FORM_SUB_HEADER,
  showReCaptcha: process.env.REACT_APP_SHOW_G_RECAPTCHA.toLowerCase() === 'yes',
  reCaptchaResCode: null,
  dataPolling: false,
  searchCustomMsgJSON: {},
  landingPgInputTextColor: process.env.REACT_APP_LANDING_PAGE_TEXT_INPUT_COLOR,
  removeFcl45HcContainer:
    process.env.REACT_APP_REMOVE_FCL_45_HC_CONTAINER.toLowerCase() === 'yes',
  showPortSwitcher:
    process.env.REACT_APP_SEARCH_SHOW_PORT_SWITCHER.toLowerCase() === 'yes' ||
    false,
  searchCardEstimateText: process.env.REACT_APP_SEARCH_CARD_ESTIMATE_TEXT || '',
  showFormCustomService:
    process.env.REACT_APP_FORM_SHOW_CUSTOM_SERVICE.toLowerCase() === 'yes',
  viewDetailsReviewText:
    process.env.REACT_APP_SEARCH_CARD_VIEW_DETAILS_REVIEW_TEXT,
  // For Form zipcode submit for new/existing factory
  formZipCodeSubmit: {
    isPickupLoading: false,
    isDeliveryLoading: false,
  },
  showOverlayFullScreen: false, //boolean type
  saveDataShowOverlay:false,
  // search dates limit for Datepicker
  searchDaysPast: process.env.REACT_APP_SEARCH_DATE_PICKER_DAYS_PAST,
  searchDaysFuture: process.env.REACT_APP_SEARCH_DATE_PICKER_DAYS_FUTURE,
  // form dates limit for Datepicker
  formDaysPast: process.env.REACT_APP_FORM_DATE_PICKER_DAYS_PAST,
  formDaysFuture: process.env.REACT_APP_FORM_DATE_PICKER_DAYS_FUTURE,
  cardVariableRateText: process.env.REACT_APP_SEARCH_CARD_VARIABLE_RATE_TEXT,

  // Delivery destination location type, ecommerce enabled or not
  showFormDeliveryEcommerce:
    process.env.REACT_APP_FORM_DELIVERY_SHOW_ECOMMERCE.toLowerCase() === 'yes',

  // Delivery destination location type, ecommerce desc text
  formDeliveryEcommerceDescText:
    process.env.REACT_APP_FORM_DELIVERY_ECOMMERCE_DESC_TEXT,

  // Delivery destination location type, amazon desc text
  formDeliveryAmazonDescText:
    process.env.REACT_APP_FORM_DELIVERY_AMAZON_DESC_TEXT,

  // Delivery destination location type, amazon mnode enabled or not
  showFormDeliveryAmazon:
    process.env.REACT_APP_FORM_DELIVERY_SHOW_AMAZON.toLowerCase() === 'yes',

  // company name section star rating color
  searchCardRatingStarColor:
    process.env.REACT_APP_SEARCH_CARD_RATING_STAR_COLOR,

  // sidebar services
  sidebarRoleIncotermServices: {
    pickup: null, //make it init null
    origin: null, //make it init null
    freight: null, //make it init null
    discharge: null, //make it init null
    ers: null, //make it init null
    delivery: null, //make it init null
  },

  // for parent website scrollbar position
  parentScrollbarPosition: 0,
  //it will contain cards state whether cards are expanded or not
  // {cardId: true, ...etc} like { 12121: true }//true means the specifix cards is expanded
  rateCardsExpandInfo: {}, //make it {}

  //should show blur cards or not
  enableSearchBlurCards: false,
};

/**
 * @type {State}
 * @description reset state object
 * @property {Object} RESET_STATE - it contains default states for FCL container counts, LCL/AIR shipment data
 */
const RESET_STATE = {
  fclCounters: {
    _20stCounter: 0,
    _40stCounter: 1,
    _40hqCounter: 0,
    _45hqCounter: 0,
  },
  lclShipment: {
    totalWeight: 100,
    weightUnit: 'KG',
    totalVolume: 1,
    volumeUnit: 'CBM',
  },
  airShipment: {
    totalWeight: 1,
    weightUnit: 'KG',
    totalVolume: 1,
    volumeUnit: 'CBM',
  },
};

/**
 * @memberof module:code_config_reducer
 * @description It's the code config reducer.
 * @type {Redux.Reducer}
 *
 * @param {Redux.Store} state - The current redux state of the code config reducer
 * @param {Redux.Action} action - Redux form action
 *
 * @returns {Redux.Store} The updated redux state
 *
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CODE_SETTINGS:
      return { ...state, ...action.payload };
    case SET_SHOW_RESULT_CARD:
      return { ...state, showNoResultCard: action.payload };
    case UPDATE_FCL_COUNTER:
      return {
        ...state,
        fclCounters: { ...state.fclCounters, ...action.payload },
      };
    case UPDATE_TRANSACTION_TYPE:
      return { ...state, ...action.payload };
    case UPDATE_LCL_SHIPMENT:
      return {
        ...state,
        lclShipment: { ...state.lclShipment, ...action.payload },
      };
    case UPDATE_AIR_SHIPMENT:
      return {
        ...state,
        airShipment: { ...state.airShipment, ...action.payload },
      };
    case RESET_CONTAINER_TYPE_SLIDER:
      const { resetTotalVolume } = action.payload;
      return {
        ...state,
        fclCounters: { ...state.fclCounters, ...RESET_STATE.fclCounters },
        lclShipment: { ...state.lclShipment, ...RESET_STATE.lclShipment, totalVolume: resetTotalVolume },
        airShipment: { ...state.airShipment, ...RESET_STATE.airShipment },
      };
    case SET_INIT_AUTH:
      // console.log("SET_INIT_AUTH: ", action.payload);
      return {
        ...state,
        initAuth: action.payload,
      };

    case SET_REFERRAL_DATA:
      return {
        ...state,
        referralData: action.payload,
      };

    case SET_UUID_URL_PARAMS_DATA:
      return {
        ...state,
        uuidUrlParamsObj: action.payload,
      };

    case SET_RECAPTCHA_RES_CODE:
      return {
        ...state,
        reCaptchaResCode: action.payload,
      };
    case SET_DATA_POLLING:
      return {
        ...state,
        dataPolling: action.payload,
      };
    case SET_SEARCH_CUSTOM_MESSAGE_JSON:
      return {
        ...state,
        searchCustomMsgJSON: action.payload,
      };

    case SET_FORM_ZIPCODE_LOADING:
      return {
        ...state,
        formZipCodeSubmit: { ...state.formZipCodeSubmit, ...action.payload },
      };
    case SET_OVERLAY_FULL_SCREEN:
      return {
        ...state,
        showOverlayFullScreen: action.payload,
      };
    case SET_TRANSPARENT_OVERLAY_SCREEN:
      return {
        ...state,
        saveDataShowOverlay: action.payload,
      };
    case UPDATE_SIDEBAR_ROLE_INCOTERM_SERVICES:
      return {
        ...state,
        sidebarRoleIncotermServices: {
          ...state.sidebarRoleIncotermServices,
          ...action.payload,
        },
      };

    case UPDATE_PARENT_SCROLLBAR_POSITION:
      return {
        ...state,
        parentScrollbarPosition: action.payload,
      };

    case UPDATE_RATE_CARD_INFO:
      return {
        ...state,
        rateCardsExpandInfo: action.payload,
      };

    case SHOW_SEARCH_BLUR_CARDS:
      return {
        ...state,
        enableSearchBlurCards: action.payload,
      };

    default:
      return state;
  }
};
