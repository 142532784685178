import { combineReducers } from 'redux';
import route from './route_reducer';
import code_config from './code_config_reducer';
import form from './form_reducer';
import dashboard from './dashboard_reducer';
import cache from './cache_reducer';

/**
 * @function
 * @name combineReducers
 * @description It turns an object whose values are different reducing functions into a single reducing function 
 * you can pass to createStore.
 * [Read more]{@link https://redux.js.org/api/combinereducers}
 *
 * @param {Object} obj - It contains all reducers i.e { route: Function, .....}
 * @returns {Function}
 */

export default combineReducers({
  route,
  form,
  code_config,
  dashboard,
  cache,
});
