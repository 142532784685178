// import './wdyr'; //why did you render lib, comment it out, it makes app slow

import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import './custom-antd.css';
import './responsive-app.css';
import './test-result-card.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';

// setup SENTRY for issue tracking in production
if (process.env.NODE_ENV !== 'development') {
  if (
    process.env.REACT_APP_SENTRY_DSN ||
    'https://9b078e0935254104abf6a09c012d0e7b@sentry.io/1785203'
  ) {

    Sentry.init({
      release: 'rate-search@0.1',
      dsn: `${
        process.env.REACT_APP_SENTRY_DSN ||
        'https://9b078e0935254104abf6a09c012d0e7b@sentry.io/1785203'
      }`,
    });
  } else {
    console.log('no dsn found');
  }
}

// remove consoles statements in production
if (process.env.NODE_ENV !== 'development') {
  // window.console.log = window.console.debug = window.console.info = window.console.error = () =>
  //   false;
}

/**
 * @function
 * @description Hook the app to the root of DOM and render it
 * @param {Function} Component - Container Component i.e. <App /> of the web app
 * @returns {Object}
 *
 * @author by prime freight
 */
// My Github {@link https://github.com/sudhir-pradhan}
const render = (Component) => {
  return ReactDOM.render(<Component />, document.getElementById('root'));
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// render the app

render(App);

// for hot module replacement

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
