import { SET_SEARCH_BLUR_CARDS_EMAIL } from '../actions/types';

/**
 * @module cache_reducer
 * @redux
 * @description It the redux reducer for caching.
 */

/**
 * @type {State}
 * @property {string | null} userEmailBlurCards - email address of user for blur cards
 */
const INITIAL_STATE = {
  userEmailBlurCards: null,
};

/**
 * @memberof module:cache_reducer
 * @description It's the cache reducer.
 * @type {Redux.Reducer}
 *
 * @param {Redux.Store} state - The current redux state of the cache reducer
 * @param {Redux.Action} action - Redux cache action
 *
 * @returns {Redux.Store} The updated redux state
 *
 */

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /**
     * update user email
     */
    case SET_SEARCH_BLUR_CARDS_EMAIL:
      return {
        ...state,
        userEmailBlurCards: action.payload,
      };

    default:
      return state;
  }
};
