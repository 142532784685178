import React from 'react';
import { Spin } from 'antd';

/**
 * @component
 * @name LoadableSpin
 * @description It's the spinner shown when a component is Lazy loading.
 */
export default function LoadableSpin() {
  return <Spin size="small" />;
}
