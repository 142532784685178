import React from 'react';
import loadable from '@loadable/component';
import './App.css';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from './store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoadableSpin from './components/common/LoadableSpin';

// code splitting
// Home page
const Home = loadable(() => import('./components/Home'), {
  fallback: <LoadableSpin />,
});


// Landing page
const LandingPage = loadable(
  () => import('./components/landing-page/LandingPage'),
  {
    fallback: <span></span>,
  }
);

// dashboard page
const DashboardPage = loadable(
  () => import('./components/dashboard/Dashboard'),
  {
    fallback: <span></span>,
  }
);

// Agent page
const AgentPage = loadable(
  () => import('./components/agent/AgentPage'),
  {
    fallback: <span></span>,
  }
);

// for 404 page not found
const NotFound = loadable(() => import('./components/not-found/NotFound'), {
  fallback: <LoadableSpin />,
});

//for testing
// const Test = loadable(() => import('./components/testComp/TestContainer'), {
//   fallback: <div style={{ textAlign: 'center' }}>Loading...</div>,
// });

//for Hot reloading
const { store, persistor } = configureStore();

/**
 * Main container Component of the web app
 * @component
 */

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <div className="app ">
            <div className="container">
              <Switch>

                <Route exact path="/" component={Home} />
                <Route exact path="/landing-page" component={LandingPage} />
                <Route exact path="/agent" component={AgentPage} />
                <Route exact path="/dashboard" component={DashboardPage} />
                <Route exact path="/*" component={NotFound} />
              </Switch>
            </div>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
